<template>
  <b-container fluid>
    <b-row class="d-block d-md-flex">
      <b-col v-for="(item, index) in statisticsList" :key="index">
        <VStatisticsCrad
          :title="item.title"
          :icon="item.icon"
          :count="item.count"
          :variant="item.variant"
          :bg="item.bg"
        >
        </VStatisticsCrad>
      </b-col>
    </b-row>
    <VBarChart
      v-if="statistics"
      title="أحصائيات زيارات المصارف"
      :data="statistics.banks_visits"
    ></VBarChart>
    <VLineChart
      v-if="statistics"
      title="نسب تسجيل الدخول"
      :data="statistics.visitors_registration"
    ></VLineChart>
    <LastVisit v-if="statistics"></LastVisit>
  </b-container>
</template>

<script>
import LastVisit from "./components/lastVisits";

export default {
  components: {
    LastVisit,
  },
  data() {
    return {
      statisticsList: [
        {
          title: "عدد المستخدمين",
          count: 0,
          icon: "people",
          variant: "#2D74FF",
          bg: "rgba(45, 116, 255, 0.1)",
        },
        {
          title: "المصارف",
          count: 0,
          icon: "vinyl",
          variant: "#F38D15",
          bg: "rgba(243, 141, 21, 0.1)",
        },
        {
          title: "الزائرين",
          count: 0,
          icon: "bricks",
          variant: "#14C25A",
          bg: "rgba(20, 192, 90, 0.1)",
        },
        {
          title: "المنشورات",
          count: 0,
          icon: "credit-card",
          variant: "#E73152",
          bg: "rgba(231, 49, 82, 0.1)",
        },
      ],
    };
  },
  computed: {
    statistics() {
      return this.$store.state.admin.statistics;
    },
  },
  methods: {
    getCountList() {
      this.statisticsList[0].count = this.statistics.count.users;
      this.statisticsList[1].count = this.statistics.count.banks;
      this.statisticsList[2].count = this.statistics.count.visitors;
      this.statisticsList[3].count = this.statistics.count.posts;
    },
  },
  mounted() {
    this.$store.dispatch("admin/get").then(() => {
      this.getCountList();
    });
  },
};
</script>

<style></style>
