<template>
  <b-card>
    <b-row align-h="between" class="my-3">
      <b-col>
        <span>اخر الزائرين الذين انضموا للنظام</span>
      </b-col>
      <b-col class="text-left">
        <b-button
          variant="info"
          size="sm"
          class="text-light"
          @click="viewAllUsers()"
          >عرض الكل</b-button
        >
      </b-col>
    </b-row>
    <b-table
      :items="items"
      :fields="fields"
      :tbody-transition-props="transProps"
      tbody-tr-class="text-muted"
      responsive
      table-class="table-font"
    >
      <template #cell(id)="data">
        {{ data.index + 1 }}
      </template>
      <template v-slot:cell(name)="{ item }">
        <img
          :src="ImageURL('user.jpg')"
          width="40"
          height="40"
          class="bg-info rounded-circle mx-3"
        />
        {{ item.name }}
      </template>
      <template v-slot:cell(email)="{ item }">
        <div v-if="item.email" class="p-2 rounded-pill green">
          {{ item.email }}
        </div>
        <p v-else>لا يوجد</p>
      </template>
      <template v-slot:cell(is_verified)="{ item }">
        <div
          class="p-2 rounded-pill"
          :class="[item.is_verified ? 'active' : 'disable']"
        >
          {{ item.is_verified ? "مفعل" : "غير مفعل" }}
        </div>
      </template>
      <template v-slot:cell(created_at)="{ item }">{{
        new Date(item.created_at).toISOString().slice(0, 10)
      }}</template>
    </b-table>
  </b-card>
</template>

<script>
import { ImageURL } from "@/utils/helper.js";

export default {
  data() {
    return {
      searchValue: "",
      transProps: {
        name: "flip-list",
      },
      fields: [
        {
          key: "id",
          label: "أ",
          sortable: true,
        },
        {
          key: "name",
          label: "الأسم",
          sortable: true,
        },
        {
          key: "phone",
          label: "رقم الهاتف",
          sortable: true,
        },
        {
          key: "email",
          label: "البريد الألكتروني",
          sortable: true,
        },
        {
          key: "is_verified",
          label: "حالة الحساب",
          sortable: true,
        },
        {
          key: "created_at",
          label: "تاريخ  الأنضمام",
          sortable: true,
        },
      ],
    };
  },
  computed: {
    items() {
      return this.$store.state.admin.statistics.last_visitors;
    },
  },
  methods: {
    viewAllUsers() {
      this.$router.push("/admin/users/guest");
    },
    ImageURL(item) {
      return item.image ? item.image : ImageURL("user.jpg");
    },
  },
};
</script>

<style lang="scss">
td[aria-colindex="2"] {
  text-align: right !important;
}
td[aria-colindex="3"] {
  direction: ltr;
}
</style>
