var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-row',{staticClass:"my-3",attrs:{"align-h":"between"}},[_c('b-col',[_c('span',[_vm._v("اخر الزائرين الذين انضموا للنظام")])]),_c('b-col',{staticClass:"text-left"},[_c('b-button',{staticClass:"text-light",attrs:{"variant":"info","size":"sm"},on:{"click":function($event){return _vm.viewAllUsers()}}},[_vm._v("عرض الكل")])],1)],1),_c('b-table',{attrs:{"items":_vm.items,"fields":_vm.fields,"tbody-transition-props":_vm.transProps,"tbody-tr-class":"text-muted","responsive":"","table-class":"table-font"},scopedSlots:_vm._u([{key:"cell(id)",fn:function(data){return [_vm._v(" "+_vm._s(data.index + 1)+" ")]}},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_c('img',{staticClass:"bg-info rounded-circle mx-3",attrs:{"src":_vm.ImageURL('user.jpg'),"width":"40","height":"40"}}),_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"cell(email)",fn:function(ref){
var item = ref.item;
return [(item.email)?_c('div',{staticClass:"p-2 rounded-pill green"},[_vm._v(" "+_vm._s(item.email)+" ")]):_c('p',[_vm._v("لا يوجد")])]}},{key:"cell(is_verified)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"p-2 rounded-pill",class:[item.is_verified ? 'active' : 'disable']},[_vm._v(" "+_vm._s(item.is_verified ? "مفعل" : "غير مفعل")+" ")])]}},{key:"cell(created_at)",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(new Date(item.created_at).toISOString().slice(0, 10)))]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }